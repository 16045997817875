import {useMediaQuery} from "usehooks-ts";
import s from "../../App.module.scss";
import {ImgWebp} from "../../helpers/ImgWebp";
import ContactsStrokeTextMobile from "../../assets/img/strokeText/contacts/text-mobile.png";
import ContactsStrokeTextMobile2x from "../../assets/img/strokeText/contacts/text-mobile@2x.png";
import ContactsStrokeTextMobileWebp from "../../assets/img/strokeText/contacts/text-mobile.webp";
import Button from "../Button/Button";
import TgSvg from "../../assets/img/contacts/tg.svg";
import GitSvg from "../../assets/img/contacts/git.svg";
import ContactsBgVideo from "../../assets/video/bg9.mp4";
import ContactsStrokeText from "../../assets/img/strokeText/contacts/text.png";
import ContactsStrokeText2x from "../../assets/img/strokeText/contacts/text@2x.png";
import ContactsStrokeTextWebp from "../../assets/img/strokeText/contacts/text.webp";
import DiagonalSvg from "../../assets/img/icons/DiagonalSvg";
import CrossSvg from "../../assets/img/icons/CrossSvg";
import SectionNum from "../SectionNum/SectionNum";

const ContactsSection = () => {
    const isMobile = useMediaQuery('(max-width:991px)')

    const toSection = (sectionId) => {
        const section = document
            .querySelector(`#${sectionId}`)
            .getBoundingClientRect()

        if (section.top < 0) {
            window.scrollTo({
                top: window.scrollY + section.top,
                behavior: 'smooth',
            })
        } else {
            window.scrollTo({
                top: section.top,
                behavior: 'smooth',
            })
        }
    }

    if (isMobile) {
        return (
            <section className={s.contacts} id='contacts'>
                <div className='container'>
                    <div className={s.sectionTop}>
                        <div className={s.sectionTop__title}>
                            Контакты
                            <div className={s.sectionTop__titleBg}>
                                <ImgWebp
                                    src={ContactsStrokeTextMobile}
                                    src2x={ContactsStrokeTextMobile2x}
                                    srcWebp={ContactsStrokeTextMobileWebp}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={s.contactsBody}>
                        <Button
                            onClick={() => toSection('callus')}
                            className={s.contactsBody__btn}
                            title='заказать проект'
                        />
                        <Button
                            className={s.contactsBody__btn}
                            href={process.env.REACT_APP_BLOG_LINK}
                            title='читать блог'
                        />
                        <div className={s.contactsBody__socials}>
                            <a
                                href={process.env.REACT_APP_TG_LINK}
                                target='_blank'
                                className={s.contactsBody__socialsItem}
                            >
                                <span></span>
                                <span></span>
                                <img src={TgSvg} alt='' />
                            </a>
                            <a
                                href={process.env.REACT_APP_GIT_LINK}
                                target='_blank'
                                className={s.contactsBody__socialsItem}
                            >
                                <span></span>
                                <span></span>
                                <img src={GitSvg} alt='' />
                            </a>
                        </div>
                        <a
                            href='mailto:business@foxsys.pro'
                            className={s.contactsBody__socialsMail}
                        >
                            business@foxsys.pro
                        </a>
                    </div>
                </div>
            </section>
        )
    } else {
        return (
            <section className={s.contacts} id='contacts'>
                <div className={s.videobg}>
                    <video autoPlay muted loop src={ContactsBgVideo}></video>
                    <span className={s._bottom}></span>
                    <span className={s._top}></span>
                </div>

                <div className='container'>
                    <div className={s.sectionTop}>
                        <div className={s.sectionTop__title}>
                            Контакты
                            <div className={s.sectionTop__titleBg}>
                                <ImgWebp
                                    src={ContactsStrokeText}
                                    src2x={ContactsStrokeText2x}
                                    srcWebp={ContactsStrokeTextWebp}
                                />
                            </div>
                        </div>

                        <div className={s.sectionTop__content}>
                            <div className={s.contactsBody}>
                                <Button
                                    className={s.contactsBody__btn}
                                    href={process.env.REACT_APP_BLOG_LINK}
                                    title='читать блог'
                                />
                                <div className={s.contactsBody__socials}>
                                    <a
                                        href={process.env.REACT_APP_TG_LINK}
                                        target='_blank'
                                        className={s.contactsBody__socialsItem}
                                    >
                                        <span></span>
                                        <span></span>
                                        <img src={TgSvg} alt='' />
                                    </a>
                                    <a
                                        href={process.env.REACT_APP_GIT_LINK}
                                        target='_blank'
                                        className={s.contactsBody__socialsItem}
                                    >
                                        <span></span>
                                        <span></span>
                                        <img src={GitSvg} alt='' />
                                    </a>
                                </div>
                                <a
                                    href='mailto:business@foxsys.pro'
                                    className={s.contactsBody__socialsMail}
                                >
                                    business@foxsys.pro
                                </a>
                            </div>

                            <div className={s.sectionTop__contentLine}></div>
                        </div>
                    </div>

                    <div className={s.contactsIcons}>
                        <div className={s.contactsIcons__item}>
                            <DiagonalSvg />
                        </div>
                        <div className={s.contactsIcons__item}>
                            <CrossSvg />
                        </div>
                        <div className={s.contactsIcons__item}>
                            <SectionNum num={'09'} />
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default ContactsSection;