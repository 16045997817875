import { useMediaQuery } from 'usehooks-ts'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import s from '../../App.module.scss'
import { ImgWebp } from '../../helpers/ImgWebp'
import FormStrokeTextMobile from '../../assets/img/strokeText/form/text-mobile.png'
import FormStrokeTextMobile2x from '../../assets/img/strokeText/form/text-mobile@2x.png'
import FormStrokeTextMobileWebp from '../../assets/img/strokeText/form/text-mobile.webp'
import Button from '../Button/Button'
import CheclSvg from '../../assets/img/icons/check.svg'
import FormBgVideo from '../../assets/video/bg6.mp4'
import FormStrokeText from '../../assets/img/strokeText/form/text.png'
import FormStrokeText2x from '../../assets/img/strokeText/form/text@2x.png'
import FormStrokeTextWebp from '../../assets/img/strokeText/form/text.webp'
import ArrowSvg from '../../assets/img/icons/ArrowSvg'
import SectionNum from '../SectionNum/SectionNum'
import { isValidEmail } from '../../helpers/EmailChecker'

const CallusSection = () => {
    const isMobile = useMediaQuery('(max-width:991px)')
    const [sendSuccess, setSendSuccess] = useState(false)

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()

    const handleEmailValidation = (email) => {
        const isValid = isValidEmail(email)

        const validityChanged =
            (errors.email && isValid) || (!errors.email && !isValid)
        if (validityChanged) {
            console.log('Fire tracker with', isValid ? 'Valid' : 'Invalid')
        }

        return isValid
    }
    const validateForm = (data) => {
        console.log(data)
        fetch('https://noxatra.ru:8081/smtp', {
            method: 'POST',
            body: JSON.stringify({
                subject: `Сайт технического продюсера`,
                message: `Имя: ${data.name}<br />
                    Адрес почты: ${data.email}<br />
                    Компания: ${data.company}<br />
                    Описание: ${data.description}
                `,
            }),
            headers: {
                'Content-type': 'application/json',
            },
        }).then((res) => {
            if (res.status === 200) {
                setSendSuccess(true)
                setTimeout(()=> setSendSuccess(false), 1000);
            } else {
                setSendSuccess(false)
            }
        })
    }
    if (isMobile) {
        return (
            <section className={s.callus} id='callus'>
                <div className='container'>
                    <div className={s.sectionTop}>
                        <div className={s.sectionTop__title}>
                            закажите <br /> проект
                            <div className={s.sectionTop__titleBg}>
                                <ImgWebp
                                    src={FormStrokeTextMobile}
                                    src2x={FormStrokeTextMobile2x}
                                    srcWebp={FormStrokeTextMobileWebp}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={s.callusBody}>
                        <form
                            action='#'
                            onSubmit={handleSubmit(validateForm)}
                            className={`${s.form} callus-form`}
                        >
                            <input
                                {...register('name', {
                                    required: true,
                                    minLength: 3,
                                })}
                                name='name'
                                placeholder='Как к вам обращаться*'
                                type='text'
                                className={s.form__item}
                            />
                            {errors.name && (
                                <span className={s._err}>Введите ваше имя</span>
                            )}
                            <input
                                {...register('email', {
                                    required: true,
                                    minLength: 3,
                                    validate: handleEmailValidation,
                                })}
                                name='email'
                                input-email="true" 
                                placeholder='Почта*'
                                type='text'
                                className={s.form__item}
                            />
                            {errors.email && (
                                <span className={s._err}>
                                    Введите свой email
                                </span>
                            )}
                            <input
                                {...register('company', {
                                    required: false,
                                })}
                                name='company'
                                placeholder='Компания'
                                type='text'
                                className={s.form__item}
                            />
                            <textarea
                                {...register('description', {
                                    required: false,
                                })}
                                name='description'
                                placeholder='описание задачи'
                                className={`${s.form__item} ${s._textarea}`}
                            ></textarea>
                            <div className={s.formBottom}>
                                <Button
                                    title={'отправить'}
                                    className={s.form__btn}
                                />
                                <div className={s.formBottom__text}>
                                    Отправляя эту форму, вы соглашаетесь <br />
                                    <a href='./docs/personal-data.pdf'>
                                        с политикой обработки персональных
                                        данных.
                                    </a>
                                </div>
                            </div>
                            <div
                                className={`${s.formSuccess} ${
                                    sendSuccess ? s.active : ''
                                }`}
                            >
                                <div className={s.formSuccess__logo}>
                                    <span></span>
                                    <span></span>
                                    <img src={CheclSvg} alt='' />
                                </div>
                                <div className={s.formSuccess__text}>
                                    Сообщение успешно отправленно
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        )
    } else {
        return (
            <section className={s.callus} id='callus'>
                <div className={s.videobg}>
                    <video autoPlay muted loop src={FormBgVideo}></video>
                    <span className={s._top}></span>
                    <span className={s._bottom}></span>
                </div>

                <div className='container'>
                    <div className={s.sectionTop}>
                        <div className={s.sectionTop__title}>
                            закажите проект
                            <div className={s.sectionTop__titleBg}>
                                <ImgWebp
                                    src={FormStrokeText}
                                    src2x={FormStrokeText2x}
                                    srcWebp={FormStrokeTextWebp}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={s.callusBody}>
                        <form
                            action='#'
                            onSubmit={handleSubmit(validateForm)}
                            className={`${s.form} callus-form`}
                        >
                            <input
                                {...register('name', {
                                    required: true,
                                    minLength: 3,
                                })}
                                name='name'
                                placeholder='Как к вам обращаться*'
                                type='text'
                                className={s.form__item}
                            />
                            {errors.name && (
                                <span className={s._err}>Введите ваше имя</span>
                            )}
                            {/* <InputMask mask='+79999999999'>
                {(inputProps) => (
                  <input
                    placeholder='номер телефона'
                    className={s.form__item}
                    type='tel'
                    disableUnderline
                  />
                )}
              </InputMask>
              {errors.phone && (
                <span className={s._err}>Введите номер телефона</span>
              )} */}
                            <input
                                {...register('email', {
                                    required: true,
                                    minLength: 3,
                                    validate: handleEmailValidation,
                                })}
                                name='email'
                                input-email="true" 
                                placeholder='Почта*'
                                type='text'
                                className={s.form__item}
                            />
                            {errors.email && (
                                <span className={s._err}>
                                    Введите свой email
                                </span>
                            )}
                            <input
                                {...register('company', {
                                    required: false,
                                })}
                                name='company'
                                placeholder='Компания'
                                type='text'
                                className={s.form__item}
                            />
                            <textarea
                                {...register('description', {
                                    required: false,
                                })}
                                name='description'
                                placeholder='описание задачи'
                                className={`${s.form__item} ${s._textarea}`}
                            ></textarea>
                            <div className={s.formBottom}>
                                <Button
                                    title={'отправить'}
                                    className={s.form__btn}
                                />
                                <div className={s.formBottom__text}>
                                    Отправляя эту форму, вы соглашаетесь <br />
                                    <a href='./docs/personal-data.pdf'>
                                        с политикой обработки персональных
                                        данных.
                                    </a>
                                </div>
                            </div>

                            <div
                                className={`${s.formSuccess} ${
                                    sendSuccess ? s.active : ''
                                }`}
                            >
                                <div className={s.formSuccess__logo}>
                                    <span></span>
                                    <span></span>
                                    <img src={CheclSvg} alt='' />
                                </div>
                                <div className={s.formSuccess__text}>
                                    Сообщение успешно отправленно
                                </div>
                            </div>
                        </form>
                        <div className={s.callusBody__icons}>
                            <div className={s.callusBody__iconsItem}>
                                <ArrowSvg />
                            </div>
                            <div className={s.callusBody__iconsItem}>
                                <SectionNum num='05' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default CallusSection
