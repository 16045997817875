import s from './Header.module.scss'
import { useMediaQuery } from 'usehooks-ts'
import logo from '../../assets/img/icons/star.svg'
import burgerBtn from '../../assets/img/header/burger.svg'
import closeBtn from '../../assets/img/header/close.svg'
import { useState } from 'react'

const Header = () => {
  const isMobile = useMediaQuery('(max-width: 991px)')
  const [burgerOpen, setBurgerOpen] = useState(false)

  const toggleBurger = () => {
    const body = document.querySelector('body')

    if (burgerOpen) {
      setBurgerOpen(false)
      body.classList.remove('lock')
    } else {
      setBurgerOpen(true)
      body.classList.add('lock')
    }
  }

  const toSection = (sectionId) => {
    const section = document
      .querySelector(`#${sectionId}`)
      .getBoundingClientRect()

    if (burgerOpen) {
      const body = document.querySelector('body')
      body.classList.remove('lock')
      setBurgerOpen(false)
      window.scrollTo({
        top: section.top,
        behavior: 'smooth',
      })
    } else {
      window.scrollTo({
        top: section.top,
        behavior: 'smooth',
      })
    }
  }

  if (isMobile) {
    return (
      <div className={`${s.header} ${burgerOpen ? s._menuOpen : ''}`}>
        <div className='container'>
          <div className={s.headerBody}>
            <div className={s.headerBody__logo}>
              <img src={logo} alt='' />
            </div>
            <div onClick={toggleBurger} className={s.headerBody__burger}>
              <img src={burgerOpen ? closeBtn : burgerBtn} alt='' />
            </div>

            <div className={s.headerLinks}>
              <div
                onClick={() => toSection('showreel')}
                className={s.headerBody__link}
              >
                showreel
              </div>
              <div
                onClick={() => toSection('clients')}
                className={s.headerBody__link}
              >
                Клиенты
              </div>
              <div
                onClick={() => toSection('workflow')}
                className={s.headerBody__link}
              >
                Принципы работы
              </div>
              <div
                onClick={() => toSection('advantages')}
                className={s.headerBody__link}
              >
                преимущества
              </div>
              <div
                onClick={() => toSection('callus')}
                className={s.headerBody__link}
              >
                закажите проект
              </div>
              <div
                onClick={() => toSection('brands')}
                className={s.headerBody__link}
              >
                бренды
              </div>
              <div
                onClick={() => toSection('media')}
                className={s.headerBody__link}
              >
                медиа
              </div>
              <div
                onClick={() => toSection('contacts')}
                className={s.headerBody__link}
              >
                контакты
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className={s.header}>
        <div className='container'>
          <div className={s.headerBody}>
            <div className={s.headerBody__logo}>
              <img src={logo} alt='' />
            </div>
            <div
              onClick={() => toSection('showreel')}
              className={s.headerBody__link}
            >
              showreel
            </div>
            <div
              onClick={() => toSection('clients')}
              className={s.headerBody__link}
            >
              Клиенты
            </div>
            <div
              onClick={() => toSection('workflow')}
              className={s.headerBody__link}
            >
              Принципы работы
            </div>
            <div
              onClick={() => toSection('advantages')}
              className={s.headerBody__link}
            >
              преимущества
            </div>
            <div
              onClick={() => toSection('callus')}
              className={s.headerBody__link}
            >
              закажите проект
            </div>
            <div
              onClick={() => toSection('brands')}
              className={s.headerBody__link}
            >
              бренды
            </div>
            <div
              onClick={() => toSection('media')}
              className={s.headerBody__link}
            >
              медиа
            </div>
            <div
              onClick={() => toSection('contacts')}
              className={s.headerBody__link}
            >
              контакты
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Header
