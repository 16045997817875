import {useMediaQuery} from "usehooks-ts";
import s from "../../App.module.scss";
import {ImgWebp} from "../../helpers/ImgWebp";
import video1StrokeText from "../../assets/img/strokeText/video/show.png";
import video1StrokeText2x from "../../assets/img/strokeText/video/show@2x.png";
import video1StrokeTextWebp from "../../assets/img/strokeText/video/show.webp";
import video2StrokeText from "../../assets/img/strokeText/video/reel.png";
import video2StrokeText2x from "../../assets/img/strokeText/video/reel@2x.png";
import video2StrokeTextWebp from "../../assets/img/strokeText/video/reel.webp";
import SectionNum from "../SectionNum/SectionNum";

const VideoSection = () => {
    const isMobile = useMediaQuery('(max-width:991px)')

    return (
        <section className={s.video} id='showreel'>
            <div className='container'>
                <div className={s.videoBody}>
                    <div className={s.videoBody__preview}>
                        <iframe
                            src={`${process.env.REACT_APP_VIDEO_LINK}?autoplay=1`}
                            title='YouTube video player'
                            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                            allowFullScreen
                        ></iframe>
                    </div>

                    {/* <div className={s.videoBody__btn}>
            <PlaySvg />
          </div> */}

                    <div className={s.videoBody__text}>
            <span className={s._top}>
              <ImgWebp
                  src={video1StrokeText}
                  src2x={video1StrokeText2x}
                  srcWebp={video1StrokeTextWebp}
              />
            </span>
                        <span className={s._bottom}>
              <ImgWebp
                  src={video2StrokeText}
                  src2x={video2StrokeText2x}
                  srcWebp={video2StrokeTextWebp}
              />
            </span>
                    </div>

                    {isMobile ? '' : <SectionNum className={s.videoBody__num} num='02' />}
                </div>
            </div>
        </section>
    )
}
export default VideoSection;