import {useMediaQuery} from "usehooks-ts";
import s from "../../App.module.scss";
import {ImgWebp} from "../../helpers/ImgWebp";
import AdvantagesStrokeTextMobile from "../../assets/img/strokeText/advantages/text-mobile.png";
import AdvantagesStrokeTextMobile2x from "../../assets/img/strokeText/advantages/text-mobile@2x.png";
import AdvantagesStrokeTextMobileWebp from "../../assets/img/strokeText/advantages/text-mobile.webp";
import StarSvg from "../../assets/img/workflow/star.svg";
import advantagesBgVideo from "../../assets/video/bg5_update9.mp4";
import AdvantagesStrokeText from "../../assets/img/strokeText/advantages/text.png";
import AdvantagesStrokeText2x from "../../assets/img/strokeText/advantages/text@2x.png";
import AdvantagesStrokeTextWebp from "../../assets/img/strokeText/advantages/text.webp";
import ArrowSvg from "../../assets/img/icons/ArrowSvg";
import CrossSvg from "../../assets/img/icons/CrossSvg";
import SectionNum from "../SectionNum/SectionNum";

const AdvantagesSection = () => {
    const isMobile = useMediaQuery('(max-width:991px)')

    if (isMobile) {
        return (
            <section className={s.advantages} id='advantages'>
                <div className='container'>
                    <div className={s.sectionTop}>
                        <div className={s.sectionTop__title}>
                            ПРЕИМУЩЕСТВА
                            <div className={s.sectionTop__titleBg}>
                                <ImgWebp
                                    src={AdvantagesStrokeTextMobile}
                                    src2x={AdvantagesStrokeTextMobile2x}
                                    srcWebp={AdvantagesStrokeTextMobileWebp}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={s.advantagesBody}>
                        <div className={s.advantagesBody__item}>
                            <div className={s.advantagesBody__itemIco}>
                                <img src={StarSvg} alt='' />
                            </div>
                            <div className={s.advantagesBody__itemTitle}>
                                Вы не платите
                                <br />
                                за мой офис
                            </div>
                            <div className={s.advantagesBody__itemDesc}>
                                <p>
                                    Вы не платите за мой офис, за простой штата и за многое
                                    другое, что закладывается в цену проекта студиями.
                                </p>
                                <p>
                                    В отличии от работы со студией команда собирается под проект и
                                    удалённо. Вы не оплачиваете ничего лишнего и платите только за
                                    фактические проделанную работу.
                                </p>
                                <p>
                                    Цена формируется на этапе технического задания и является
                                    финальной в рамках проекта.
                                </p>
                            </div>
                        </div>
                        <div className={s.advantagesBody__item}>
                            <div className={s.advantagesBody__itemIco}>
                                <img src={StarSvg} alt='' />
                            </div>
                            <div className={s.advantagesBody__itemTitle}>
                                Вы не занимаетесь <br /> микроменеджментом
                            </div>
                            <div className={s.advantagesBody__itemDesc}>
                                <p>
                                    В отличии от работы с фрилансерами вы не занимаетесь контролем
                                    каждого отдельного исполнителя. Вы ставите общую задачу и
                                    получаете решение вашей задачи. Вы не проверяете сделал ли
                                    каждый человек свои задачи в срок. Вы не рискуете с тем, что
                                    человек пропадёт или не сделает работу.
                                </p>
                                <p>
                                    Эту ответственность беру на себя я. И если конкретный
                                    исполнитель пропал или не сделал работу, то новый исполнитель
                                    будет подобран максимально оперативно и в тот же бюджет. Таким
                                    образом, что чаще всего вы даже этого не заметите.
                                </p>
                            </div>
                        </div>
                        <div className={s.advantagesBody__item}>
                            <div className={s.advantagesBody__itemIco}>
                                <img src={StarSvg} alt='' />
                            </div>
                            <div className={s.advantagesBody__itemTitle}>
                                Вы получаете
                                <br />
                                качественный результат
                            </div>
                            <div className={s.advantagesBody__itemDesc}>
                                <p>
                                    Мы работаем вдвоём с арт-директором. Я в разработке больше 8
                                    лет, он в дизайне более 15 лет. Совместно мы реализовали более
                                    60 проектов с бюджетами от 100 тысяч рублей до 30 миллионов.
                                </p>
                                <p>
                                    Контроль проекта осуществляется двумя профессионалами в своём
                                    деле, а не менеджером и специалистом, которого сейчас смогла
                                    выделить студия. Мы поможем вам придумать концепцию, которая
                                    решит вашу задачу.
                                </p>
                            </div>
                        </div>
                        <div className={s.advantagesBody__item}>
                            <div className={s.advantagesBody__itemIco}>
                                <img src={StarSvg} alt='' />
                            </div>
                            <div className={s.advantagesBody__itemTitle}>
                                Вы получаете лучших
                                <br />
                                специалистов рынка
                            </div>
                            <div className={s.advantagesBody__itemDesc}>
                                <p>
                                    В отличии от студий, у которых сформированная экспертиза и
                                    штат, мы подбираем специалистов под конкретный проект и
                                    бюджет, что даёт нам возможность оптимально реализовывать
                                    поставленные задачи.
                                </p>
                                <p>
                                    У студии может не быть в штате специалистов по VFX, рендеру,
                                    сложным технологиям и математическим решениям. Мы же можем
                                    привлечь специалистов, которые нужны именно вам.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    } else {
        return (
            <section className={s.advantages} id='advantages'>
                <div className={s.videobg}>
                    <video autoPlay muted loop src={advantagesBgVideo}></video>
                    <span className={s._top}></span>
                    <span className={s._bottom}></span>
                </div>

                <div className='container'>
                    <div className={s.sectionTop}>
                        <div className={s.sectionTop__title}>
                            ПРЕИМУЩЕСТВА
                            <div className={s.sectionTop__titleBg}>
                                <ImgWebp
                                    src={AdvantagesStrokeText}
                                    src2x={AdvantagesStrokeText2x}
                                    srcWebp={AdvantagesStrokeTextWebp}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={s.advantagesBody}>
                        <div className={s.advantagesBody__item}>
                            <div className={s.advantagesBody__itemIco}>
                                <img src={StarSvg} alt='' />
                            </div>
                            <div className={s.advantagesBody__itemTitle}>
                                Вы не платите
                                <br />
                                за мой офис
                            </div>
                            <div className={s.advantagesBody__itemDesc}>
                                <p>
                                    Вы не платите за мой офис, за простой штата и за многое
                                    другое, что закладывается в цену проекта студиями.
                                </p>
                                <p>
                                    В отличии от работы со студией команда собирается под проект и
                                    удалённо. Вы не оплачиваете ничего лишнего и платите только за
                                    фактические проделанную работу.
                                </p>
                                <p>
                                    Цена формируется на этапе технического задания и является
                                    финальной в рамках проекта.
                                </p>
                            </div>
                        </div>
                        <div className={s.advantagesBody__item}>
                            <div className={s.advantagesBody__itemIco}>
                                <img src={StarSvg} alt='' />
                            </div>
                            <div className={s.advantagesBody__itemTitle}>
                                Вы не занимаетесь <br /> микроменеджментом
                            </div>
                            <div className={s.advantagesBody__itemDesc}>
                                <p>
                                    В отличии от работы с фрилансерами вы не занимаетесь контролем
                                    каждого отдельного исполнителя. Вы ставите общую задачу и
                                    получаете решение вашей задачи. Вы не проверяете сделал ли
                                    каждый человек свои задачи в срок. Вы не рискуете с тем, что
                                    человек пропадёт или не сделает работу.
                                </p>
                                <p>
                                    Эту ответственность беру на себя я. И если конкретный
                                    исполнитель пропал или не сделал работу, то новый исполнитель
                                    будет подобран максимально оперативно и в тот же бюджет. Таким
                                    образом, что чаще всего вы даже этого не заметите.
                                </p>
                            </div>
                        </div>
                        <div className={s.advantagesBody__item}>
                            <div className={s.advantagesBody__itemIco}>
                                <img src={StarSvg} alt='' />
                            </div>
                            <div className={s.advantagesBody__itemTitle}>
                                Вы получаете
                                <br />
                                качественный результат
                            </div>
                            <div className={s.advantagesBody__itemDesc}>
                                <p>
                                    Мы работаем вдвоём с арт-директором. Я в разработке больше 8
                                    лет, он в дизайне более 15 лет. Совместно мы реализовали более
                                    60 проектов с бюджетами от 100 тысяч рублей до 30 миллионов.
                                </p>
                                <p>
                                    Контроль проекта осуществляется двумя профессионалами в своём
                                    деле, а не менеджером и специалистом, которого сейчас смогла
                                    выделить студия. Мы поможем вам придумать концепцию, которая
                                    решит вашу задачу.
                                </p>
                            </div>
                        </div>
                        <div className={s.advantagesBody__item}>
                            <div className={s.advantagesBody__itemIco}>
                                <img src={StarSvg} alt='' />
                            </div>
                            <div className={s.advantagesBody__itemTitle}>
                                Вы получаете лучших
                                <br />
                                специалистов рынка
                            </div>
                            <div className={s.advantagesBody__itemDesc}>
                                <p>
                                    В отличии от студий, у которых сформированная экспертиза и
                                    штат, мы подбираем специалистов под конкретный проект и
                                    бюджет, что даёт нам возможность оптимально реализовывать
                                    поставленные задачи.
                                </p>
                                <p>
                                    У студии может не быть в штате специалистов по VFX, рендеру,
                                    сложным технологиям и математическим решениям. Мы же можем
                                    привлечь специалистов, которые нужны именно вам.
                                </p>
                            </div>
                        </div>

                        <div className={s.advantagesBody__icons}>
                            <dir className={s.advantagesBody__iconsItem}>
                                <ArrowSvg />
                            </dir>
                            <dir className={s.advantagesBody__iconsItem}>
                                <CrossSvg />
                            </dir>
                            <dir className={s.advantagesBody__iconsItem}>
                                <SectionNum num={'05'} />
                            </dir>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default AdvantagesSection;