import {useMediaQuery} from "usehooks-ts";
import {useEffect} from "react";
import Ellipsis from "ellipsis.js";
import s from "../../App.module.scss";
import {ImgWebp} from "../../helpers/ImgWebp";
import MediatrokeTextMobile from "../../assets/img/strokeText/media/text-mobile.png";
import MediatrokeTextMobile2x from "../../assets/img/strokeText/media/text-mobile@2x.png";
import MediatrokeTextMobileWebp from "../../assets/img/strokeText/media/text-mobile.webp";
import MediaBgVideo from "../../assets/video/bg8.mp4";
import MediaStrokeText from "../../assets/img/strokeText/media/text.png";
import MediaStrokeText2x from "../../assets/img/strokeText/media/text@2x.png";
import MediaStrokeTextWebp from "../../assets/img/strokeText/media/text.webp";
import SectionNum from "../SectionNum/SectionNum";

const articleTitle1 = process.env.REACT_APP_ARTICLE_TITLE_01
const articleTitle2 = process.env.REACT_APP_ARTICLE_TITLE_02
const articleTitle3 = process.env.REACT_APP_ARTICLE_TITLE_03
const articleText1 = process.env.REACT_APP_ARTICLE_TEXT_01
const articleText2 = process.env.REACT_APP_ARTICLE_TEXT_02
const articleText3 = process.env.REACT_APP_ARTICLE_TEXT_03

const MediaSection = () => {
    const isMobile = useMediaQuery('(max-width:991px)')

    useEffect(() => {
        Ellipsis({
            lines: 5,
        })
    })

    if (isMobile) {
        return (
            <section className={s.media} id='media'>
                <div className='container'>
                    <div className={s.sectionTop}>
                        <div className={s.sectionTop__title}>
                            выступления
                            <div className={s.sectionTop__titleBg}>
                                <ImgWebp
                                    src={MediatrokeTextMobile}
                                    src2x={MediatrokeTextMobile2x}
                                    srcWebp={MediatrokeTextMobileWebp}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={s.mediaBody}>
                        <div className={s.mediaBody__row}>
                            <div className={s.mediaBody__item}>
                                <iframe
                                    allowFullScreen=''
                                    loading='lazy'
                                    src={process.env.REACT_APP_MEDIA_VIDEO_01}
                                ></iframe>
                            </div>
                            <div className={s.mediaBody__item}>
                                <iframe
                                    allowFullScreen=''
                                    loading='lazy'
                                    src={process.env.REACT_APP_MEDIA_VIDEO_02}
                                ></iframe>
                            </div>
                            <div className={s.mediaBody__item}>
                                <iframe
                                    allowFullScreen=''
                                    loading='lazy'
                                    src={process.env.REACT_APP_MEDIA_VIDEO_03}
                                ></iframe>
                            </div>
                        </div>

                        <div className={`${s.sectionTop} ${s.mediaArticles__title}`}>
                            <div className={s.sectionTop__title}>
                                Статьи
                                <div className={s.sectionTop__titleBg}>
                                    <ImgWebp
                                        src={MediatrokeTextMobile}
                                        src2x={MediatrokeTextMobile2x}
                                        srcWebp={MediatrokeTextMobileWebp}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={s.mediaArticles}>
                            <a href={process.env.REACT_APP_MEDIA_ARTICLE_LINK_01} target='_blank' className={s.mediaArticles__item}>
                                <div className={s.mediaArticles__itemImg}>
                                    <img
                                        src={process.env.REACT_APP_MEDIA_ARTICLE_IMG_01}
                                        alt=''
                                    />
                                </div>
                                <div className={s.mediaArticles__itemDesc}>
                                    <div
                                        className={s.title}
                                        dangerouslySetInnerHTML={{ __html: articleTitle1 }}
                                    ></div>
                                    <div className={`${s.text} clamp`}>{articleText1}</div>
                                </div>
                            </a>
                            <a href={process.env.REACT_APP_MEDIA_ARTICLE_LINK_02} target='_blank' className={s.mediaArticles__item}>
                                <div className={s.mediaArticles__itemImg}>
                                    <img
                                        src={process.env.REACT_APP_MEDIA_ARTICLE_IMG_02}
                                        alt=''
                                    />
                                </div>
                                <div className={s.mediaArticles__itemDesc}>
                                    <div
                                        className={s.title}
                                        dangerouslySetInnerHTML={{ __html: articleTitle2 }}
                                    ></div>
                                    <div className={`${s.text} clamp`}>{articleText2}</div>
                                </div>
                            </a>
                            <a href={process.env.REACT_APP_MEDIA_ARTICLE_LINK_03}target='_blank' className={s.mediaArticles__item}>
                                <div className={s.mediaArticles__itemImg}>
                                    <img
                                        src={process.env.REACT_APP_MEDIA_ARTICLE_IMG_03}
                                        alt=''
                                    />
                                </div>
                                <div className={s.mediaArticles__itemDesc}>
                                    <div
                                        className={s.title}
                                        dangerouslySetInnerHTML={{ __html: articleTitle3 }}
                                    ></div>
                                    <div className={`${s.text} clamp`}>{articleText3}</div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        )
    } else {
        return (
            <section className={s.media} id='media'>
                <div className={s.videobg}>
                    <video autoPlay muted loop src={MediaBgVideo}></video>
                    <span className={s._bottom}></span>
                    <span className={s._top}></span>
                </div>

                <div className='container'>
                    <div className={s.sectionTop}>
                        <div className={s.sectionTop__title}>
                            выступления
                            <div className={s.sectionTop__titleBg}>
                                <ImgWebp
                                    src={MediaStrokeText}
                                    src2x={MediaStrokeText2x}
                                    srcWebp={MediaStrokeTextWebp}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={s.mediaBody}>
                        <div className={s.mediaBody__row}>
                            <div className={s.mediaBody__item}>
                                <iframe
                                    allowFullScreen=''
                                    loading='lazy'
                                    src={process.env.REACT_APP_MEDIA_VIDEO_01}
                                ></iframe>
                            </div>
                            <div className={s.mediaBody__item}>
                                <iframe
                                    allowFullScreen=''
                                    loading='lazy'
                                    src={process.env.REACT_APP_MEDIA_VIDEO_02}
                                ></iframe>
                            </div>
                            <div className={s.mediaBody__item}>
                                <iframe
                                    allowFullScreen=''
                                    loading='lazy'
                                    src={process.env.REACT_APP_MEDIA_VIDEO_03}
                                ></iframe>
                            </div>
                        </div>

                        <div className={s.mediaArticles__title}>статьи</div>
                        <div className={s.mediaArticles}>
                            <a
                                href={process.env.REACT_APP_MEDIA_ARTICLE_LINK_01}
                                target='_blank'
                                className={s.mediaArticles__item}
                            >
                                <div className={s.mediaArticles__itemImg}>
                                    <img
                                        src={process.env.REACT_APP_MEDIA_ARTICLE_IMG_01}
                                        alt=''
                                    />
                                </div>
                                <div className={s.mediaArticles__itemDesc}>
                                    <div
                                        className={s.title}
                                        dangerouslySetInnerHTML={{ __html: articleTitle1 }}
                                    ></div>
                                    <div className={`${s.text} clamp`}>{articleText1}</div>
                                </div>
                            </a>
                            <a
                                href={process.env.REACT_APP_MEDIA_ARTICLE_LINK_02}
                                target='_blank'
                                className={s.mediaArticles__item}
                            >
                                <div className={s.mediaArticles__itemImg}>
                                    <img
                                        src={process.env.REACT_APP_MEDIA_ARTICLE_IMG_02}
                                        alt=''
                                    />
                                </div>
                                <div className={s.mediaArticles__itemDesc}>
                                    <div
                                        className={s.title}
                                        dangerouslySetInnerHTML={{ __html: articleTitle2 }}
                                    ></div>
                                    <div className={`${s.text} clamp`}>{articleText2}</div>
                                </div>
                            </a>
                            <a
                                href={process.env.REACT_APP_MEDIA_ARTICLE_LINK_03}
                                target='_blank'
                                className={s.mediaArticles__item}
                            >
                                <div className={s.mediaArticles__itemImg}>
                                    <img
                                        src={process.env.REACT_APP_MEDIA_ARTICLE_IMG_03}
                                        alt=''
                                    />
                                </div>
                                <div className={s.mediaArticles__itemDesc}>
                                    <div
                                        className={s.title}
                                        dangerouslySetInnerHTML={{ __html: articleTitle3 }}
                                    ></div>
                                    <div className={`${s.text} clamp`}>{articleText3}</div>
                                </div>
                            </a>
                        </div>

                        <div className={s.mediaIcons}>
                            <div className={s.mediaIcons__item}>
                                <SectionNum num='08' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default MediaSection;