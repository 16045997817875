import {useMediaQuery} from "usehooks-ts";
import s from "../../App.module.scss";
import {ImgWebp} from "../../helpers/ImgWebp";
import WorkflowStrokeTextMobile from "../../assets/img/strokeText/workflow/text-mobile.png";
import WorkflowStrokeTextMobile2x from "../../assets/img/strokeText/workflow/text-mobile@2x.png";
import WorkflowStrokeTextMobileWebp from "../../assets/img/strokeText/workflow/text-mobile.webp";
import StarSvg from "../../assets/img/workflow/star.svg";
import BrainSvg from "../../assets/img/workflow/brain.svg";
import DocumentSvg from "../../assets/img/workflow/document.svg";
import GearSvg from "../../assets/img/workflow/gears.svg";
import TargetSvg from "../../assets/img/workflow/target.svg";
import workflowBgVideo from "../../assets/video/bg4.mp4";
import WorkflowStrokeText from "../../assets/img/strokeText/workflow/text.png";
import WorkflowStrokeText2x from "../../assets/img/strokeText/workflow/text@2x.png";
import WorkflowStrokeTextWebp from "../../assets/img/strokeText/workflow/text.webp";
import LineSvg from "../../assets/img/workflow/line.svg";
import ArrowSvg from "../../assets/img/icons/ArrowSvg";
import SectionNum from "../SectionNum/SectionNum";

const WorkflowSection = () => {
    const isMobile = useMediaQuery('(max-width:991px)')

    if (isMobile) {
        return (
            <section className={s.workflow} id='workflow'>
                <div className='container'>
                    <div className={s.sectionTop}>
                        <div className={s.sectionTop__title}>
                            принцип <br /> работы
                            <div className={s.sectionTop__titleBg}>
                                <ImgWebp
                                    src={WorkflowStrokeTextMobile}
                                    src2x={WorkflowStrokeTextMobile2x}
                                    srcWebp={WorkflowStrokeTextMobileWebp}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={s.workflowBody}>
                        <div className={s.workflowBody__item}>
                            <div className={s.workflowBody__itemTitle}>старт</div>
                            <div className={s.workflowBody__itemIcon}>
                                <img src={StarSvg} alt='' />
                            </div>
                        </div>
                        <div className={s.workflowBody__item}>
                            <div className={s.workflowBody__itemTitle}>препродакшн</div>
                            <div className={s.workflowBody__itemIcon}>
                                <img src={BrainSvg} alt='' />
                            </div>
                        </div>
                        <div className={s.workflowBody__item}>
                            <div className={s.workflowBody__itemTitle}>согласование</div>
                            <div className={s.workflowBody__itemIcon}>
                                <img src={DocumentSvg} alt='' />
                            </div>
                        </div>
                        <div className={s.workflowBody__item}>
                            <div className={s.workflowBody__itemTitle}>продакшн</div>
                            <div className={s.workflowBody__itemIcon}>
                                <img src={GearSvg} alt='' />
                            </div>
                        </div>
                        <div className={s.workflowBody__item}>
                            <div className={s.workflowBody__itemTitle}>результат</div>
                            <div className={s.workflowBody__itemIcon}>
                                <img src={TargetSvg} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    } else {
        return (
            <section className={s.workflow} id='workflow'>
                <div className={s.videobg}>
                    <video autoPlay muted loop src={workflowBgVideo}></video>
                    <span className={s._bottom}></span>
                    <span className={s._top}></span>
                </div>

                <div className='container'>
                    <div className={s.sectionTop}>
                        <div className={s.sectionTop__title}>
                            принцип работы
                            <div className={s.sectionTop__titleBg}>
                                <ImgWebp
                                    src={WorkflowStrokeText}
                                    src2x={WorkflowStrokeText2x}
                                    srcWebp={WorkflowStrokeTextWebp}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={s.workflowBody}>
                        <div className={s.workflowBody__item}>
                            <div className={s.line}>
                                <div className={s.lineIcon}>
                                    <img src={StarSvg} alt='' />
                                </div>
                                <div className={s.lineCircle}></div>
                            </div>
                            <div className={s.name}>старт</div>
                        </div>
                        <div className={s.workflowBody__item}>
                            <div className={s.line}>
                                <div className={s.lineIcon}>
                                    <img src={BrainSvg} alt='' />

                                    <div className={s.radial}>
                                        <div className={s.radialItem}>
                                            <div className={s.radialItem__tooltip}>
                                                <span></span>
                                                <div className={s.radialItem__tooltipContent}>
                                                    Сбор бизнес требоаваний
                                                </div>
                                            </div>
                                            <div className={s.radialItem__tooltip}>
                                                <span></span>
                                                <div className={s.radialItem__tooltipContent}>
                                                    Подбор команды
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${s.radialItem} ${s._hidden}`}></div>
                                        <div className={s.radialItem}>
                                            <div className={s.radialItem__tooltip}>
                                                <span></span>
                                                <div className={s.radialItem__tooltipContent}>
                                                    Составление ТЗ
                                                </div>
                                            </div>
                                            <div className={s.radialItem__tooltip}>
                                                <span></span>
                                                <div className={s.radialItem__tooltipContent}>
                                                    Формирование бюджета
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={s.lineCircle}></div>
                            </div>
                            <div className={s.name}>препродакшн</div>
                        </div>
                        <div className={s.workflowBody__item}>
                            <div className={s.line}>
                                <div className={s.lineIcon}>
                                    <img src={DocumentSvg} alt='' />
                                </div>
                                <div className={s.lineCircle}></div>
                            </div>
                            <div className={s.name}>Согласование</div>
                        </div>
                        <div className={s.workflowBody__item}>
                            <div className={s.line}>
                                <div className={s.lineIcon}>
                                    <img src={GearSvg} alt='' />

                                    <div className={s.radial}>
                                        <div className={s.radialItem}>
                                            <div className={s.radialItem__tooltip}>
                                                <span></span>
                                                <div className={s.radialItem__tooltipContent}>
                                                    Разработка
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${s.radialItem}`}>
                                            <div className={`${s.radialItem__tooltip} ${s._bottom}`}>
                                                <span></span>
                                                <div className={s.radialItem__tooltipContent}>
                                                    Дизайн
                                                </div>
                                            </div>
                                        </div>
                                        <div className={s.radialItem}>
                                            <div className={s.radialItem__tooltip}>
                                                <span></span>
                                                <div className={s.radialItem__tooltipContent}>
                                                    Публикация
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={s.lineCircle}></div>
                            </div>
                            <div className={s.name}>продакшн</div>
                        </div>
                        <div className={s.workflowBody__item}>
                            <div className={s.line}>
                                <div className={s.lineIcon}>
                                    <img src={TargetSvg} alt='' />
                                </div>
                                <div className={s.lineCircle}></div>
                            </div>
                            <div className={s.name}>РЕзультат</div>
                        </div>

                        <div className={s.workflowBody__line}>
                            <img src={LineSvg} alt='' />
                        </div>

                        <div className={s.workflowIcons}>
                            <div className={s.workflowIcons__item}>
                                <ArrowSvg />
                            </div>
                            <SectionNum className={s.workflowIcons__item} num='04' />
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default WorkflowSection;