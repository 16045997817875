const ArrowSvg = () => (
  <svg
    width='50'
    height='50'
    viewBox='0 0 50 50'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_453_724)'>
      <rect y='-0.62793' width='6' height='50' fill='white' />
      <rect
        y='50.3721'
        width='6'
        height='50'
        transform='rotate(-90 0 50.3721)'
        fill='white'
      />
      <path
        d='M45.7541 0.372348L49.9963 0.00071482L49.9968 4.61499L4.24104 50.3707L-0.00160083 46.1281L45.7541 0.372348Z'
        fill='white'
      />
      <rect x='0.5' y='0.5' width='49' height='49' stroke='white' />
    </g>
    <defs>
      <clipPath id='clip0_453_724'>
        <rect width='50' height='50' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default ArrowSvg
