import ArrowDownSvg from '../../assets/img/icons/ArrowDownSvg'
import s from './Button.module.scss'

const Button = ({ className, title, href, isToDown, onClick }) => {
  if (isToDown) {
    return (
      <button
        onClick={onClick}
        className={`${s.button} ${isToDown ? s._arrow : ''} ${className}`}
      >
        <ArrowDownSvg />

        <span className={s._hover}>
          <ArrowDownSvg />
          <span className={s.top}></span>
          <span className={s.bottom}></span>
          <span className={s.left}></span>
          <span className={s.right}></span>
        </span>
        <span className={s.top}></span>
        <span className={s.bottom}></span>
        <span className={s.left}></span>
        <span className={s.right}></span>
      </button>
    )
  }
  if (href) {
    return (
      <a href={href} target='_blank' className={`${s.button} ${className}`}>
        {title}
        <span className={s._hover}>
          {title}
          <span className={s.top}></span>
          <span className={s.bottom}></span>
          <span className={s.left}></span>
          <span className={s.right}></span>
        </span>

        <span className={s.top}></span>
        <span className={s.bottom}></span>
        <span className={s.left}></span>
        <span className={s.right}></span>
      </a>
    )
  } else {
    return (
      <button onClick={onClick} className={`${s.button} ${className}`}>
        {title}
        <span className={s._hover}>
          {title}
          <span className={s.top}></span>
          <span className={s.bottom}></span>
          <span className={s.left}></span>
          <span className={s.right}></span>
        </span>

        <span className={s.top}></span>
        <span className={s.bottom}></span>
        <span className={s.left}></span>
        <span className={s.right}></span>
      </button>
    )
  }
}

export default Button
