import {useMediaQuery} from "usehooks-ts";
import s from "../../App.module.scss";
import {ImgWebp} from "../../helpers/ImgWebp";
import PersonBorder from "../../assets/img/main/card/border.png";
import PersonBorder2x from "../../assets/img/main/card/border@2x.png";
import PersonBorderWebp from "../../assets/img/main/card/border.webp";
import PersonImg from "../../assets/img/main/card/person.png";
import PersonImg2x from "../../assets/img/main/card/person@2x.png";
import PersonImgWebp from "../../assets/img/main/card/person.webp";
import Button from "../Button/Button";
import mainBgVideo from "../../assets/video/bg1.mp4";
import CrossSvg from "../../assets/img/icons/CrossSvg";
import ArrowSvg from "../../assets/img/icons/ArrowSvg";
import VersionSvg from "../../assets/img/icons/VersionSvg";
import SectionNum from "../SectionNum/SectionNum";
import {MouseParallaxChild, MouseParallaxContainer} from "react-parallax-mouse";
import CardParallax from "../../assets/img/main/card/card.png";
import CardParallax2x from "../../assets/img/main/card/card@2x.png";
import CardParallaxWebp from "../../assets/img/main/card/card.webp";
import Phone from "../../assets/img/main/card/phone.png";
import Phone2x from "../../assets/img/main/card/phone@2x.png";
import PhoneWebp from "../../assets/img/main/card/phone.webp";
import mainStrokeText from "../../assets/img/strokeText/main/text.png";
import mainStrokeText2x from "../../assets/img/strokeText/main/text@2x.png";
import mainStrokeTextWebp from "../../assets/img/strokeText/main/text.webp";

const MainSection = () => {
    const isMobile = useMediaQuery('(max-width:991px)')

    const toSection = (sectionId) => {
        const section = document
            .querySelector(`#${sectionId}`)
            .getBoundingClientRect()

        window.scrollTo({
            top: section.top,
            behavior: 'smooth',
        })
    }

    if (isMobile) {
        return <MainSectionMobile toSection={toSection} />
    } else {
        return <MainSectionPC toSection={toSection}/>
    }
}

const MainSectionPC = (props)=>{
    return (
        <main className={s.main}>
            <div className={s.videobg}>
                <video autoPlay muted loop src={mainBgVideo}></video>
                <span className={s._bottom}></span>
            </div>

            <div className='container'>
                <div className={s.mainBody}>
                    <div className={s.mainIcons}>
                        <div className={s.mainIcons__item}>
                            <CrossSvg />
                        </div>
                        <div className={s.mainIcons__item}>
                            <ArrowSvg />
                        </div>
                        <div className={s.mainIcons__item}>
                            <VersionSvg />
                        </div>
                        <div className={s.mainIcons__item}>
                            <SectionNum num={'01'} />
                        </div>
                    </div>

                    <MouseParallaxContainer
                        className={s.mainParallax}
                        useWindowMouseEvents
                        globalFactorX={0.2}
                        globalFactorY={0.2}
                    >
                        <MouseParallaxChild
                            factorX={0.5}
                            factorY={0.5}
                            className={s.mainCard}
                        >
                            {/* <div className={s.mainCard}> */}
                            <div className={s.mainCard__img}>
                                <div className={s.mainCard__imgBorder}>
                                    <ImgWebp
                                        src={PersonBorder}
                                        src2x={PersonBorder2x}
                                        srcWebp={PersonBorderWebp}
                                    />
                                </div>
                                <ImgWebp
                                    src={PersonImg}
                                    src2x={PersonImg2x}
                                    srcWebp={PersonImgWebp}
                                />
                            </div>
                            <div className={s.mainCard__title}>Григорий Дядиченко</div>
                            <div className={s.mainCard__bottom}>
                                <div className={s.mainCard__bottomPos}>
                                    технический <br /> продюссер
                                </div>
                                <Button
                                    className={s.mainCard__bottomBtn}
                                    title={'читать блог'}
                                    href={process.env.REACT_APP_BLOG_LINK}
                                />
                            </div>
                            {/* </div> */}
                        </MouseParallaxChild>

                        <MouseParallaxChild
                            className={s.mainParallax__item}
                            factorX={0.4}
                            factorY={0.4}
                        >
                            <div className={s.mainCard__parallaxItem}>
                                <ImgWebp
                                    src={CardParallax}
                                    src2x={CardParallax2x}
                                    srcWebp={CardParallaxWebp}
                                />
                            </div>
                        </MouseParallaxChild>
                        <MouseParallaxChild
                            className={s.mainParallax__item}
                            factorX={0.3}
                            factorY={0.3}
                        >
                            <div className={s.mainCard__parallaxItem}>
                                <ImgWebp
                                    src={CardParallax}
                                    src2x={CardParallax2x}
                                    srcWebp={CardParallaxWebp}
                                />
                            </div>
                        </MouseParallaxChild>
                        <MouseParallaxChild
                            className={s.mainParallax__item}
                            factorX={0.2}
                            factorY={0.2}
                        >
                            <div className={s.mainCard__parallaxItem}>
                                <ImgWebp
                                    src={CardParallax}
                                    src2x={CardParallax2x}
                                    srcWebp={CardParallaxWebp}
                                />
                            </div>
                        </MouseParallaxChild>
                        <MouseParallaxChild
                            className={s.mainParallax__item}
                            factorX={0.05}
                            factorY={0.1}
                        >
                            <div className={s.mainCard__parallaxItem}>
                                <ImgWebp
                                    src={CardParallax}
                                    src2x={CardParallax2x}
                                    srcWebp={CardParallaxWebp}
                                />
                            </div>
                        </MouseParallaxChild>

                        <MouseParallaxChild
                            className={s.mainParallax__item}
                            factorX={0}
                            factorY={0}
                        >
                            <div className={s.mainCard__phone}>
                                <ImgWebp src={Phone} src2x={Phone2x} srcWebp={PhoneWebp} />
                            </div>
                        </MouseParallaxChild>
                    </MouseParallaxContainer>

                    <div className={s.mainInfo}>
                        <div className={s.mainInfo__title}>
                            <span>it как </span>
                            <span>в кино</span>
                        </div>
                        <Button
                            onClick={() => props.toSection('callus')}
                            title={'заказать проект'}
                            className={s.mainInfo__btn}
                        />
                    </div>
                    <ImgWebp
                        className={s.mainBody__text}
                        src={mainStrokeText}
                        src2x={mainStrokeText2x}
                        srcWebp={mainStrokeTextWebp}
                    />
                </div>
            </div>
        </main>
    )
}

const MainSectionMobile = (props)=>{
    return (
        <main className={s.main}>
            <div className='container'>
                <div className={s.mainBody}>
                    <div className={s.mainInfo__title}>
                        <span>it как </span>
                        <span>в кино</span>
                    </div>

                    <div className={s.mainCard}>
                        <div className={s.mainCard__img}>
                            <div className={s.mainCard__imgBorder}>
                                <ImgWebp
                                    src={PersonBorder}
                                    src2x={PersonBorder2x}
                                    srcWebp={PersonBorderWebp}
                                />
                            </div>
                            <ImgWebp
                                src={PersonImg}
                                src2x={PersonImg2x}
                                srcWebp={PersonImgWebp}
                            />
                        </div>
                        <div className={s.mainCard__title}>Григорий Дядиченко</div>
                        <div className={s.mainCard__bottom}>
                            <div className={s.mainCard__bottomPos}>
                                технический <br /> продюссер
                            </div>
                            <Button
                                className={s.mainCard__bottomBtn}
                                href={process.env.REACT_APP_BLOG_LINK}
                                title={'читать блог'}
                            />
                        </div>
                    </div>

                    <Button
                        onClick={() => props.toSection('callus')}
                        title={'заказать проект'}
                        className={s.mainInfo__btn}
                    />
                </div>
            </div>
        </main>
    )
}

export default MainSection;