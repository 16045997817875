import {useMediaQuery} from "usehooks-ts";
import s from "../../App.module.scss";
import {ImgWebp} from "../../helpers/ImgWebp";
import BrandsStrokeTextMobile from "../../assets/img/strokeText/brands/text-mobile.png";
import BrandsStrokeTextMobile2x from "../../assets/img/strokeText/brands/text-mobile@2x.png";
import BrandsStrokeTextMobileWebp from "../../assets/img/strokeText/brands/text-mobile.webp";
import Brand01Svg from "../../assets/img/brands/brand01.svg";
import Brand02Svg from "../../assets/img/brands/brand02.svg";
import Brand03Svg from "../../assets/img/brands/brand03.svg";
import Brand04Svg from "../../assets/img/brands/brand04.svg";
import brandsBgVideo from "../../assets/video/bg7.mp4";
import BrandsStrokeText from "../../assets/img/strokeText/brands/text.png";
import BrandsStrokeText2x from "../../assets/img/strokeText/brands/text@2x.png";
import BrandsStrokeTextWebp from "../../assets/img/strokeText/brands/text.webp";
import CrossSvg from "../../assets/img/icons/CrossSvg";
import SectionNum from "../SectionNum/SectionNum";

const BrandsSection = () => {
    const isMobile = useMediaQuery('(max-width:991px)')

    if (isMobile) {
        return (
            <section className={s.brands} id='brands'>
                <div className='container'>
                    <div className={s.sectionTop}>
                        <div className={s.sectionTop__title}>
                            бренды
                            <div className={s.sectionTop__titleBg}>
                                <ImgWebp
                                    src={BrandsStrokeTextMobile}
                                    src2x={BrandsStrokeTextMobile2x}
                                    srcWebp={BrandsStrokeTextMobileWebp}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={s.brandsTop__text}>
                        За время 6-ти летней работы сформировалось несколько продуктов.
                        Можно ознакомиться с моей экспертизой через эти бренды.
                    </div>
                    <div className={s.brandsBody}>
                        <a href={process.env.REACT_APP_BRAND_LINK_01} className={s.brandsBody__item}>
                            <span></span>
                            <span></span>
                            <img src={Brand01Svg} alt='' />
                        </a>
                        <a href={process.env.REACT_APP_BRAND_LINK_02} className={s.brandsBody__item}>
                            <span></span>
                            <span></span>
                            <img src={Brand02Svg} alt='' />
                        </a>
                        <a href={process.env.REACT_APP_BRAND_LINK_03} className={s.brandsBody__item}>
                            <span></span>
                            <span></span>
                            <img src={Brand03Svg} alt='' />
                        </a>
                        <a href={process.env.REACT_APP_BRAND_LINK_04} className={s.brandsBody__item}>
                            <span></span>
                            <span></span>
                            <img src={Brand04Svg} alt='' />
                        </a>
                    </div>
                </div>
            </section>
        )
    } else {
        return (
            <section className={s.brands} id='brands'>
                <div className={s.videobg}>
                    <video autoPlay muted loop src={brandsBgVideo}></video>
                    <span className={s._bottom}></span>
                    <span className={s._top}></span>
                </div>

                <div className='container'>
                    <div className={s.sectionTop}>
                        <div className={s.sectionTop__title}>
                            бренды
                            <div className={s.sectionTop__titleBg}>
                                <ImgWebp
                                    src={BrandsStrokeText}
                                    src2x={BrandsStrokeText2x}
                                    srcWebp={BrandsStrokeTextWebp}
                                />
                            </div>
                        </div>

                        <div className={s.sectionTop__content}>
                            <div className={s.brandsTop__text}>
                                За время 6-ти летней работы сформировалось несколько продуктов.
                                Можно ознакомиться с моей экспертизой через эти бренды.
                            </div>

                            <div className={s.sectionTop__contentLine}></div>
                        </div>
                    </div>

                    <div className={s.brandsBody}>
                        <a href={process.env.REACT_APP_BRAND_LINK_01} className={s.brandsBody__item}>
                            <span></span>
                            <span></span>
                            <img src={Brand01Svg} alt='' />
                        </a>
                        <a href={process.env.REACT_APP_BRAND_LINK_02} className={s.brandsBody__item}>
                            <span></span>
                            <span></span>
                            <img src={Brand02Svg} alt='' />
                        </a>
                        <a href={process.env.REACT_APP_BRAND_LINK_03} className={s.brandsBody__item}>
                            <span></span>
                            <span></span>
                            <img src={Brand03Svg} alt='' />
                        </a>
                        <a href={process.env.REACT_APP_BRAND_LINK_04} className={s.brandsBody__item}>
                            <span></span>
                            <span></span>
                            <img src={Brand04Svg} alt='' />
                        </a>

                        <div className={s.clientsIcons}>
                            <div className={s.clientsIcons__item}>
                                <CrossSvg />
                            </div>
                            <div></div>
                            <SectionNum className={s.clientsIcons__item} num='07' />
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default BrandsSection;