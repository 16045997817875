const ArrowDownSvg = () => (
  <svg
    width='50'
    height='50'
    viewBox='0 0 50 50'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_438_2299)'>
      <path
        d='M3.86719 13.8672L25 35L46.1328 13.8672'
        stroke='white'
        strokeWidth='10'
      />
    </g>
    <defs>
      <clipPath id='clip0_438_2299'>
        <rect width='50' height='50' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export default ArrowDownSvg
