import {useMediaQuery} from "usehooks-ts";
import s from "../../App.module.scss";
import {ImgWebp} from "../../helpers/ImgWebp";
import ClientsStrokeTextMobile from "../../assets/img/strokeText/clients/text-mobile.png";
import ClientsStrokeTextMobile2x from "../../assets/img/strokeText/clients/text-mobile@2x.png";
import ClientsStrokeTextMobileWebp from "../../assets/img/strokeText/clients/text-mobile.webp";
import ClientsLogo01Svg from "../../assets/img/clients/logo01.svg";
import ClientsLogo02Svg from "../../assets/img/clients/logo02.svg";
import ClientsLogo03Svg from "../../assets/img/clients/logo03.svg";
import ClientsLogo04Svg from "../../assets/img/clients/logo04.svg";
import ClientsLogo05Svg from "../../assets/img/clients/logo05.svg";
import ClientsLogo06Svg from "../../assets/img/clients/logo06.svg";
import clientsBgVideo from "../../assets/video/bg3.mp4";
import ClientsStrokeText from "../../assets/img/strokeText/clients/text.png";
import ClientsStrokeText2x from "../../assets/img/strokeText/clients/text@2x.png";
import ClientsStrokeTextWebp from "../../assets/img/strokeText/clients/text.webp";
import ArrowSvg from "../../assets/img/icons/ArrowSvg";
import CrossSvg from "../../assets/img/icons/CrossSvg";
import SectionNum from "../SectionNum/SectionNum";

const ClientsSection = () => {
    const isMobile = useMediaQuery('(max-width:991px)')

    if (isMobile) {
        return (
            <section className={s.clients} id='clients'>
                <div className='container'>
                    <div className={s.sectionTop}>
                        <div className={s.sectionTop__title}>
                            проекты
                            <div className={s.sectionTop__titleBg}>
                                <ImgWebp
                                    src={ClientsStrokeTextMobile}
                                    src2x={ClientsStrokeTextMobile2x}
                                    srcWebp={ClientsStrokeTextMobileWebp}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    } else {
        return (
            <section className={s.clients} id='clients'>
                <div className={s.videobg}>
                    <video autoPlay muted loop src={clientsBgVideo}></video>
                    <span className={s._bottom}></span>
                    <span className={s._top}></span>
                </div>

                <div className='container'>
                    <div className={s.sectionTop}>
                        <div className={s.sectionTop__title}>
                            проекты
                            <div className={s.sectionTop__titleBg}>
                                <ImgWebp
                                    src={ClientsStrokeText}
                                    src2x={ClientsStrokeText2x}
                                    srcWebp={ClientsStrokeTextWebp}
                                />
                            </div>
                        </div>

                        <div className={s.sectionTop__content}>
                            <div className={s.clientsTop}>
                                <div className={s.clientsTop__item}>
                                    <div className={s.clientsTop__itemValue}>6+</div>
                                    <div className={s.clientsTop__itemText}>
                                        Более 6 лет <br /> в продакшене
                                    </div>
                                </div>
                                <div className={s.clientsTop__item}>
                                    <div className={s.clientsTop__itemValue}>100+</div>
                                    <div className={s.clientsTop__itemText}>
                                        реализованых <br /> проектов
                                    </div>
                                </div>
                                <div className={s.clientsTop__item}>
                                    <div className={s.clientsTop__itemValue}>4+</div>
                                    <div className={s.clientsTop__itemText}>
                                        продуктов <br /> и брендов
                                    </div>
                                </div>
                            </div>

                            <div className={s.sectionTop__contentLine}></div>
                        </div>
                    </div>

                </div>
            </section>
        )
    }
}

export default ClientsSection;