
import Header from './components/Header/Header'
import MainSection from './components/Sections/MainSection';
import VideoSection from "./components/Sections/VideoSection";
import ClientsSection from "./components/Sections/ClientsSection";
import WorkflowSection from "./components/Sections/WorkflowSection";
import AdvantagesSection from "./components/Sections/AdvantagesSection";
import CallusSection from "./components/Sections/CallUsSection";
import BrandsSection from "./components/Sections/BrandsSection";
import MediaSection from "./components/Sections/MediaSection";
import ContactsSection from "./components/Sections/ContactsSection";

function App() {
  return (
    <div className='wrapper'>
      <div className='content'>
        <Header />
        <MainSection />
        <VideoSection />
        <ClientsSection />
        <WorkflowSection />
        <AdvantagesSection />
        <CallusSection />
        <BrandsSection />
        <MediaSection />
        <ContactsSection />
      </div>
    </div>
  )
}

export default App
