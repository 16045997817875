import s from './SectionNum.module.scss'

const SectionNum = ({ num, className }) => {
  return (
    <div className={`${s.num} ${className}`}>
      <span className={s._left}></span>
      <span className={s._right}></span>
      <span className={s._bottom}></span>
      <span className={s._num}>{num}</span>
      <span className={s._lastnum}>/09</span>
    </div>
  )
}

export default SectionNum
